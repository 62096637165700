@import "src/styles/variables";

@mixin desktop {
    @media screen and (min-width: $screen-very-large) {
    @content;
    }
}

@mixin laptop {
    @media screen and (min-width: $screen-large) {
    @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $screen-large - 1px) {
    @content;
    }
}

@mixin tablet-min {
    @media screen and (min-width: $screen-medium) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $screen-medium - 1px) {
    @content;
    }
}

@mixin not-mobile {
    @media screen and (min-width: $screen-medium) {
        @content;
    }
}

@mixin font-lato-overwrite {
    font-family: $font-lato;
    font-weight: 400;
}
