.thin-scroll-bar {
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-scroll-bar-thumb;
    }

    &::-webkit-scrollbar-track {
        background: $color-scroll-bar-track;
    }
}
