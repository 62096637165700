.layout-flex {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        flex: 1 1 0;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow: auto;

        > * {
            width: 100%;
        }
    }
}

