.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        background-color: var(--color-primary-25);

        .mat-form-field-label {
            color: var(--color-primary-700);
        }
    }

    .mat-form-field-underline {
        background-color: var(--color-primary-100);
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    {
        -webkit-text-fill-color: var(--color-primary-700);
        transition: background-color 5000s ease-in-out 0s;
    }
}
