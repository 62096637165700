.popup-backdrop {
    background-color: rgba(0, 0, 0, .2);
}

.popup-backdrop-high-opacity {
    background-color: rgba(0, 0, 0, .75);
}

.popup-backdrop-intensive {
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
}

.custom-popup {
    width: 100%;
    min-width: 310px;
    height: 100%;
    overflow: auto;

    &.with-overlapping-header {
        .popup-header.mat-dialog-title {
            margin-bottom: 0;
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
        }

        .mat-dialog-content {
            margin-top: -10px;
            padding-top: 20px;
        }
    }

    .mat-dialog-container {
        padding: 0;
        background: none;
        border-radius: 0;
        box-shadow: none;
        text-align: center;
        min-width: 300px;
        overflow: visible;
        position: relative;

        > * {
            display: block;
            height: 100%;
            overflow: auto;
        }

        .ios-fix {
            @extend .thin-scroll-bar;
            min-height: 100%;
            box-sizing: border-box;
            padding: 66px 0;
        }

        .mat-dialog-title {
            z-index: 3;
        }

        .mat-dialog-content {
            z-index: 2;
        }

        .mat-dialog-content, .mat-dialog-title {
            position: relative;

        }
    }

    .mat-dialog-title {
        min-width: ($screen-min - 10px);
        max-width: ($screen-medium - 10px);
        background-color: $color-foreground;
        border: 1px solid $color-border;
        border-radius: 5px;
        color: $color-main-text;
        text-align: center;
        width: 100%;
        padding: 5px;
        margin: 0 auto 5px;
        font-size: 30px;
        font-weight: 200;
    }

    .mat-dialog-content {
        text-align: initial;
        background: $color-foreground;
        padding: 10px;
        margin: 0;
        border-radius: 5px;
        border: 1px solid $color-border;
        display: inline-block;
        overflow: visible;
        max-height: unset;
        max-width: $screen-medium;
    }

    &.with-increased-width {
        .mat-dialog-content {
            width: 88%;
            max-width: 650px;
            min-width: 310px;
        }
    }
}
