@import "src/styles/variables";

.sub-tags-menu.mat-menu-panel {
    position: relative;
    max-width: 330px;
    min-width: 330px;
    border-radius: 11px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5);
    height: fit-content;

    .sub-tags-menu-content {
        overflow-y: auto;
        max-height: 300px;
        margin: 0 25px 0 3px;

    }

    .sub-tag {
        font-weight: 800;
        color: $color-primary-100;
        text-decoration: none;
        font-size: 25px;
        display: inline-block;
        width: 100%;
    }

    #menu-label {
        font-weight: 800;
        margin: 20px 20px 10px 20px;
        font-size: 23px;
        opacity: .4;
    }

    #close-button {
        $font-size: 30px;
        background: $color-foreground-alter;
        position: absolute;
        cursor: pointer;
        top: 20px;
        right: 16px;
        font-size: $font-size;

        mat-icon {
            width: $font-size;
            height: $font-size;
            font-size: $font-size;
        }
    }

    ul {
        margin: 0;
        list-style: none;
        padding-inline-start: 0;

        li {
            margin-bottom: 5px;
            font-size: 25px;
            padding-left: 40px;
            position: relative;

            &:before {
                content: '\25cb';
                display: block;
                position: absolute;
                left: 14px;
                top: 50%;
                transform: translateY(-50%);
                color: $color-primary-50;
            }
        }
    }
}
