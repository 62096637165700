@import "src/styles/mixins";
@import "src/styles/utils";

.layout-auth {
    .content {
        @extend .thin-scroll-bar;

        position: relative;
        height: 100%;
        text-align: center;
        padding: 30px 20px 20px;
        flex-direction: column;

    }

    .row {
        > * {
            width: 100%;
        }
    }

    form .row {
        padding-bottom: 20px;
    }

    header {
        padding: 8px 10px;
        border-bottom: 1px solid $color-border;

        .part:not(#image-container) {
            min-width: 40px;
            min-height: 1px;
            text-align: center;
            flex: 0 0 auto;
        }

        #image-container {
            padding: 0 20px;
            text-align: center;

            img {
                max-height: 36px;
                max-width: 100%;
            }
        }
    }
}
