.cdk-overlay-pane {
    pointer-events: none !important;

    > * {
        pointer-events: auto;
    }

    .mat-snack-bar-container {

        &.private-chat-message-notification {
            margin-top: 70px !important;
            max-width: 768px;
            width: 100vw;
        }
    }
}

.mat-snack-bar-container.chat-message-on-copy-snack-bar {
    border-radius: 20px;
    min-width: unset;
}

.mat-snack-bar-handset .mat-snack-bar-container.chat-message-on-copy-snack-bar {
    width: auto;
    margin: 8px auto;

}
