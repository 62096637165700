@import "src/styles/mixins";

* {
    box-sizing: border-box;
    outline: none;
}

html, body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-color: $color-primary-25;
    color: $color-main-text;
    font-family: $font-default;
    font-weight: 300;
}

.app-root {
    min-width: $screen-min-s;
    max-width: $screen-medium;
    background-color: $color-background;
    width: 100%;
    margin: 0 auto;
    position: relative;

    &.screen-large {
        max-width: $screen-max;
    }
}

button {
    font-family: inherit;

    &:not(:disabled) {
        cursor: pointer;
    }

    &.btn-flat {
        background: none;
        border: none;
        outline: none;
        padding: 0;
    }
}

a {
    color: $color-primary;

    &:visited {
        color: $color-primary;
    }
}

.material-icons {
    font-family: $font-icons;
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    user-select: none;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.error {
    color: $color-warn;
}

.color-primary {
    color: $color-primary;
}

.color-primary-50 {
    color: $color-primary-50;
}

.color-primary-100 {
    color: $color-primary-100;
}

.color-primary-700 {
    color: $color-primary-700;
}

.color-accent {
    color: $color-accent;
}

// this class should be the last among colors qualifiers to allow override when applied
.color-facilitator {
    color: $color-facilitator;
}

.btn-follow {
    font-size: 16px;
    font-weight: 300;
    color: $color-primary;

    &.followed {
        mat-icon {
            color: $color-warn-700;
        }
    }

    span {
        display: inline-block;
        width: 100%;
    }

    mat-icon {
        color: inherit;
    }

}

.hidden-scrollbar {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.separator-with-text {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $color-primary-700;

    &:before, &:after {
        content: '';
        flex: 1;
        padding-top: 5px;
        border-bottom: 1px solid $color-border;
    }

    &:before {
        margin-right: .5em;
    }

    &:after {
        margin-left: .5em;
    }
}

.font-lato-overwrite {
    @include font-lato-overwrite;
}

.bouncing-button {
    background: url('/assets/images/arrow-green.png') no-repeat center;
    animation-name: bounce-horizontal-1;
    animation-timing-function: ease-out;
    animation-iteration-count: 5;
    animation-duration: 1.5s;
    display: inline-block;
    background-size: contain;
    width: 65px;
    height: 25px;
    z-index: 1;

    &.oblique {
        transform: rotateZ(-45deg);
        animation-name: bounce-horizontal-2;
    }
}

@keyframes bounce-horizontal-1 {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes bounce-horizontal-2 {
    0% {
        transform: rotateZ(-45deg) translateX(0);
    }
    50% {
        transform: rotateZ(-45deg) translateX(-20px);
    }
    100% {
        transform: rotateZ(-45deg) translateX(0);
    }
}
