@import "src/styles/variables";

.resources-menu.mat-menu-panel {
    border-radius: 0 0 11px 11px;
    box-shadow: 0 17px 29px 0 rgba(32, 20, 31, 0.5);
    max-width: unset;
    max-height: 600px;
    width: 395px;
    top: 65px;
    overflow: hidden;

    .mat-menu-content {
        padding: unset;
    }
}
