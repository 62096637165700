.un-auth-covered {
    position: relative;

    &.inline-wrapper{
        display: inline-block;
    }

    &, *:not(.un-auth-click-handler) {
        pointer-events: none !important;
    }

    .un-auth-click-handler {
        pointer-events: all !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}