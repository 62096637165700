.manage-feed-settings-menu.mat-menu-panel {
    position: relative;
    min-width: 153px;
    border-radius: 11px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5);

    ul {
        margin: 0;
        list-style: none;
        padding-inline-start: 0;

        li {
            margin-bottom: 5px;
            font-size: 21px;
            padding-left: 40px;
            position: relative;

            &:before {
                content: '\25cb';
                display: block;
                position: absolute;
                left: 14px;
                top: 50%;
                transform: translateY(-50%);
                color: $color-accent;
            }

            a {
                text-decoration: none;
            }

            a, button {
                display: inline-block;
                text-align: left;
                width: 100%;
                font-size: 21px;
                font-weight: 800;
                line-height: 40px;
                color: $color-primary-700;
            }
        }
    }
}
