.header-nav {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    mat-icon {
        width: auto;
        height: auto;

        &, ::ng-deep svg {
            max-height: 36px;
            max-width: 100%;
        }
    }
}

