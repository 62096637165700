@use '@angular/material' as mat;
@import "angular-material-theme";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($eat-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($eat-theme);

$primary: map-get($eat-theme, 'primary');

:root {
    --color-primary: #{mat.get-color-from-palette($primary, 500)};
    --color-primary-25: #{mat.get-color-from-palette($primary, 25)};
    --color-primary-50: #{mat.get-color-from-palette($primary, 50)};
    --color-primary-100: #{mat.get-color-from-palette($primary, 100)};
    --color-primary-700: #{mat.get-color-from-palette($primary, 700)};
    --color-primary-contrast: #{mat.get-color-from-palette($primary, '500-contrast')};
    --color-primary-50-contrast: #{mat.get-color-from-palette($primary, '50-contrast')};
    --color-primary-100-contrast: #{mat.get-color-from-palette($primary, '100-contrast')};
    --color-primary-700-contrast: #{mat.get-color-from-palette($primary, '700-contrast')};
    --color-accent: #{mat.get-color-from-palette($primary, 'A500')};
    --color-accent-100: #{mat.get-color-from-palette($primary, 'A100')};
    --color-accent-200: #{mat.get-color-from-palette($primary, 'A200')};
    --color-accent-700: #{mat.get-color-from-palette($primary, 'A700')};
    --color-accent-contrast: #{mat.get-color-from-palette($primary, 'A500-contrast')};
    --color-warn: #{mat.get-color-from-palette($primary, 'W500')};
    --color-warn-contrast: #{mat.get-color-from-palette($primary, 'W500-contrast')};
    --color-warn-700: #{mat.get-color-from-palette($primary, 'W700')};
    --color-background: #ffffff;
    --color-background-light: #fbfbfb;
    --color-background-alter: #1b1a19;
    --color-foreground: #f5f6fe;
    --color-foreground-alter: #e2eaf3;
    --color-border: #dadeeb;
    --color-border-alter: #8590a6;
    --color-background-hover: #{mat.get-color-from-palette(map-get($eat-theme, 'background'), 'hover')};
    --color-disabled-background: #{mat.get-color-from-palette(map-get($eat-theme, 'background'), 'disabled-button')};
    --color-disabled-text: #{mat.get-color-from-palette(map-get($eat-theme, 'foreground'), 'disabled-text')};

    --color-facilitator: #4a32ff;

    --color-tool-button-background: #aec1e6;
    --color-tool-button-box-shadow: #5774ac;

    --color-feed-title-button-border: #b6cfe5;

    --color-voice-chat-background: #163450;
    --color-voice-chat-button-background: #2b567e;
    --color-voice-chat-border: #ececec;
    --color-voice-chat-notification-chat-button: #91c9ef;
    --color-project: #7821A0;
    --color-dark-purple: #3F115B;

}

@import "typography";
@import "button";
@import "dialog";
@import "tooltip";
@import "form-field";

