.main-menu, .profile-menu {
    button, a, a:visited {
        font-size: 20px;
        color: $color-primary;

        white-space: normal;
        text-overflow: inherit;
        line-height: 100%;
        display: flex;
        align-items: center;
    }

    .mat-menu-submenu-icon {
        fill: $color-primary;
    }

    hr {
        border: 1px solid $color-primary;
        border-top: none;
    }

    .upcoming {
        color: $color-primary;
        text-align: center;
        padding: 0 16px;
        font-size: 24px;
        font-weight: 400;
    }
}

.profile-menu {
    .without-icon {
        padding-left: 60px;
    }

    .mat-menu-item mat-icon {
        margin-right: 0;
        width: 40px;
        font-size: 40px;
        height: auto;
    }
}
