@import "src/styles/variables";

.faq-chapters-menu.mat-menu-panel {
    position: relative;
    max-width: 330px;
    min-width: 330px;
    border-radius: 11px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5);
    height: fit-content;

    .faq-chapters-menu-content {
        overflow-y: auto;
        max-height: 300px;
        margin: 0 25px 0 3px;

    }

    .chapter {
        font-weight: 800;
        color: $color-primary-700;
        text-decoration: none;
        font-size: 19px;
        display: inline-block;
        width: 100%;
    }

    #menu-label {
        font-weight: 800;
        margin: 20px 20px 10px 16px;
        font-size: 19px;
        opacity: .4;
    }

    ul {
        margin: 0;
        list-style: none;
        padding-inline-start: 0;

        li {
            margin-bottom: 5px;
            font-size: 19px;
            padding-left: 40px;
            position: relative;

            &:before {
                content: '\25cb';
                font-size: 16px;
                display: block;
                position: absolute;
                left: 14px;
                top: 50%;
                transform: translateY(-50%);
                color: $color-accent;
            }
        }
    }
}
