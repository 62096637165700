.edit-avatar-panel {
    width: 100%;
    height: 100%;

    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        max-width: $screen-medium;
        margin: 0 auto;
    }
}
