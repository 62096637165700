.menu-popup {
    mat-dialog-container {
        padding: 0;
        background: none;
        border-radius: 0;
        max-width: $screen-medium;
        margin: 0 auto;
        overflow: hidden;
    }

    mat-dialog-content {
        max-height: calc(100vh - (#{$default-header-height} * 2));
        -webkit-overflow-scrolling: touch;
    }
}
