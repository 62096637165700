$screen-min: 320px;
$screen-min-s: 374px;
$screen-medium: 768px;
$screen-large: 1024px;
$screen-very-large: 1200px;
$screen-max: 1440px;

$color-primary: var(--color-primary);
$color-primary-contrast: var(--color-primary-contrast);
$color-primary-25: var(--color-primary-25);
$color-primary-50: var(--color-primary-50);
$color-primary-50-contrast: var(--color-primary-50-contrast);
$color-primary-100: var(--color-primary-100);
$color-primary-100-contrast: var(--color-primary-100-contrast);
$color-primary-700: var(--color-primary-700);
$color-primary-700-contrast: var(--color-primary-700-contrast);
$color-accent: var(--color-accent);
$color-accent-100: var(--color-accent-100);
$color-accent-200: var(--color-accent-200);
$color-accent-300: var(--color-accent-300);
$color-accent-700: var(--color-accent-700);
$color-accent-contrast: var(--color-accent-contrast);
$color-warn: var(--color-warn);
$color-warn-700: var(--color-warn-700);
$color-warn-contrast: var(--color-warn-contrast);
$color-background: var(--color-background);
$color-background-light: var(--color-background-light);
$color-background-alter: var(--color-background-alter);
$color-foreground: var(--color-foreground);
$color-foreground-alter: var(--color-foreground-alter);
$color-border: var(--color-border);
$color-border-alter: var(--color-border-alter);
$color-background-hover: var(--color-background-hover);
$color-disabled-background: var(--color-disabled-background);
$color-disabled-text: var(--color-disabled-text);

// custom colors

$color-facilitator: var(--color-facilitator);

$color-tool-button-background: var(--color-tool-button-background);
$color-tool-button-box-shadow: var(--color-tool-button-box-shadow);

$color-feed-title-button-border: var(--color-feed-title-button-border);
$color-voice-chat-background: var(--color-voice-chat-background);
$color-voice-chat-button-background: var(--color-voice-chat-button-background);
$color-voice-chat-border: var(--color-voice-chat-border);
$color-voice-chat-notification-chat-button: var(--color-voice-chat-notification-chat-button);
$color-project: var(--color-project);
$color-footer-background: rgb(130 63 170 / 90%);
$color-footer-links: var(--color-dark-purple);
$color-border2: #CCCFD4;
$color-text-light-purple: #A39DBA;
$color-welcome-page-text-background: #F1EFC0A7;

$color-affirmation-background-gradient: linear-gradient(153deg, #BC7DCC 0%, $color-primary 100%);
$color-affirmation-scrollbar-thumb: rgba(220, 218, 229, 0.40);
$color-affirmation-scrollbar-track: #8F50A9;
$color-affirmation-button-background: rgba(255, 255, 255, 0.6);

$color-care-librarian-background-transparent: rgba(220, 218, 229, 0.60);
// end custom colors

$color-main-text: $color-primary-700;

$color-scroll-bar-track: $color-border;
$color-scroll-bar-thumb: $color-border-alter;

$color-chat-message-current-user: $color-foreground-alter;
$color-chat-message-other-user: $color-foreground;
$color-chat-system-info: $color-disabled-text;

$color-users-search-input-wrapper-background: $color-foreground;

$font-icons: 'Material Icons';
$font-lato: 'Lato', serif;

$default-popup-padding: 15px;
$default-header-height: 80px;

$max-z-index: 9998;
