@use '@angular/material' as mat;

$font-default: "jaf-domus", serif;

$eat-colors: (
        25: #F6EFFE,
        50: #DDC3E8,
        100: #C79CDA,
        500: #823FAA,
        700: #603497,
        A100: #ffe6d6,
        A200: #f86a0e,
        A300: #e99e0b,
        A500: #C79CDA,
        A700: #00ff00,
        W500: #f44336,
        W700: #ff0000,
        contrast: (
                25: #000000,
                50: #000000,
                100: #ffffff,
                500: #ffffff,
                700: #ffffff,
                A100: #000000,
                A200: #000000,
                A500: #ffffff,
                A700: #000000,
                W500: #ffffff,
                W700: #ffffff,
        )
);

$eat-typography: mat.define-typography-config(
        $font-family: $font-default,
        $headline: mat.define-typography-level(30px, 36px, 300),
        $title: mat.define-typography-level(26px, 36px, 300),
        $subheading-2: mat.define-typography-level(20px, 28px, 300),
        $body-1: mat.define-typography-level(16px, 24px, 300),
        $body-2: mat.define-typography-level(18px, 24px, 300),
        $button: mat.define-typography-level(18px, 44px, 200),
        $caption: mat.define-typography-level(14px, 18px, 300)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$eat-palette-primary: mat.define-palette($eat-colors);
$eat-palette-accent: mat.define-palette($eat-colors, A500, A500, A500);
$eat-palette-warn: mat.define-palette($eat-colors, W500, W500, W500);

// Create the theme object (a Sass map containing all of the palettes).
$eat-theme: mat.define-light-theme($eat-palette-primary, $eat-palette-accent, $eat-palette-warn);
